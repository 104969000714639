import { AuthService } from './shared/services/auth.service';
import { Component, OnInit } from '@angular/core';

import { Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'kalkulator';
  user = false;

  ngOnInit() {
    this.authService.getUser().subscribe(user => this.user = user);
  }

  previousUrl: string;

  constructor(private renderer: Renderer2, private router: Router, private authService: AuthService, private http: HttpClient) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1)
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }
          this.previousUrl = currentUrlSlug;
        }
      });
  }



}
