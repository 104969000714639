import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Subject, BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { dCode } from '../models/discount-codes.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private product: Product;
  private productsList: Product[] = [];
  public productList$ = new Subject<Product[]>();

  public discount$ = new ReplaySubject<number>();

  public currency = {
    eur: 4.37,
    usd: 3.97,
    cad: 3.00,
    gbp: 5.05
  };

  constructor(private http: HttpClient) { }

  getProduct(): Product {
    return this.product;
  }
  updateProduct(product: Product) {
    this.product = product;
  }
  getProductsList() {
    return this.productsList;
  }

  addProductToList(product: Product) {
    this.productsList.push(product);
    this.productList$.next(this.productsList);
  }

  deleteProduct(index): void {
    this.productsList.splice(index, 1);
    this.productList$.next(this.productsList);
  }

  // getDiscountCodesFromDB() {
  //   this.http.get('/assets/discount-codes.json').subscribe((codes: dCode[]) => {
  //     this.discountCodesList = codes;
  //   });
  // }

  // checkDiscount(code: string) {
  //   const pickedDiscount = this.discountCodesList.find(discount => discount.code === code);
  //   if(pickedDiscount) {
  //     this.discount$.next(pickedDiscount);
  //   }
  // }

  changeDiscountValue(value) {
    this.discount$.next(value);
  }

}
