import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { CartService } from '../shared/services/cart.service';
import { Product } from '../shared/models/product.model';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css'],
})
export class PrintComponent implements OnInit, OnDestroy {

  productsList: Array<Product>;
  productsTotal: number = 0;
  totalPrice: number = 0;
  discount: number;
  currency: object;

  constructor(private cart: CartService, private renderer: Renderer2, private router: Router) { }

  ngOnInit() {
    this.cart.discount$.subscribe(discountValue => this.discount = discountValue);
    this.productsList = this.cart.getProductsList();

    this.renderer.addClass(document.body, 'print');
    this.currency = this.cart.currency;

    if (this.productsList.length > 0) {
      const productsTotal = this.productsList
        .map(changes => changes.selectedParams.quantity)
        .reduce((prev, next) => prev + next);
      this.productsTotal = productsTotal;

      const totalPrice = this.productsList
        .map(changes => changes.selectedParams.totalPrice)
        .reduce((prev, next) => prev + next);
      this.totalPrice = totalPrice - ((totalPrice * this.discount || 0) / 100);
    }
    // this.captureScreen();
    // this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'print');
  }

  captureScreen() {
    const data = document.getElementById('invoice');
    console.log(data);
    html2canvas(data).then(canvas => {
      const imgWidth = 208
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('gvg.pdf');
    });
  }


}
