import { Observable } from 'rxjs';
import { CartService } from '../shared/services/cart.service';
import { Component, OnInit } from '@angular/core';
import { Product } from '../shared/models/product.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  productsList: Product[];

  constructor(private cart: CartService) { }

  ngOnInit() {
    this.productsList = this.cart.getProductsList();
  }

  delete(index) {
    this.cart.deleteProduct(index);
  }

}
