import { Observable } from 'rxjs';
import { CartService } from '../shared/services/cart.service';
import { Component, OnInit } from '@angular/core';
import { Product } from '../shared/models/product.model';
import { dCode } from '../shared/models/discount-codes.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  productsList: Array<Product> = [];
  productsTotal: number = 0;
  priceBeforeDiscount: number = 0;
  discount: number = null;
  totalPrice: number = 0;
  currency: object;

  constructor(private cart: CartService) { }

  ngOnInit() {
    this.cart.productList$.subscribe(products => {
      this.productsList = products;
      this.calculateProducts();
    });
    this.cart.discount$.subscribe(discountValue => this.discount = discountValue);

    this.currency = this.cart.currency;
    // this.cart.getDiscountCodesFromDB();
    // this.cart.getDiscount().subscribe(disc => this.discount = disc.value);
  }

  calculateProducts() {
    this.discountControl();
    if(this.productsList.length > 0) {
      const productsTotal = this.productsList
        .map(changes => changes.selectedParams.quantity)
        .reduce((prev, next) => prev + next);
      this.productsTotal = productsTotal;
      const totalPrice = this.productsList
        .map(changes => changes.selectedParams.totalPrice)
        .reduce((prev, next) => prev + next);
      this.priceBeforeDiscount = totalPrice;
      this.totalPrice = totalPrice - ((totalPrice * this.discount) / 100);
    } else {
      this.productsTotal = 0;
      this.totalPrice = 0;
      this.priceBeforeDiscount = 0;
    }
  }

  discountControl() {
    if(this.discount > 50 ) {
      this.discount = 50;
    } else if (this.discount < 0) {
      this.discount = 0;
    }
    this.cart.changeDiscountValue(this.discount);
  }

}
