import { AuthService } from '../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.getUser().subscribe(user => this.user = user);

    // Auto Login
    // const autoLogin = setInterval(() => {
    //   this.authService.autoLogin();
    //   clearInterval(autoLogin);
    // }, 500);

  }

  onSubmit(form: NgForm) {
    this.authService.login(form);
  }

}
