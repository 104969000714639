import { NgForm, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CartService } from '../shared/services/cart.service';
import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Product } from '../shared/models/product.model';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription, of, Observable } from 'rxjs';
import { promise } from 'protractor';
import { map, findIndex } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Window } from '../shared/models/windows.model';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  product: Product;
  windowsList: Window[];
  formSubscription: Subscription;

  inputMaxWidth: number;
  inputMaxHeight: number;
  inputMinWidth: number;
  inputMinHeight: number;

  productParams = this.fb.group({
    width: ['', Validators.min(0)],
    height: ['', Validators.min(0)],
    window: [null, Validators.required],
    constructionPrice: [0],
    windowPrice: [0],
    productPrice: [0],
    quantity: [1, [Validators.min(0), Validators.max(10000), Validators.pattern('^[0-9]*$')]],
    totalPrice: [0],
  });

  constructor(private cart: CartService, private router: Router, private fb: FormBuilder, private http: HttpClient) {}

  ngOnInit() {
    this.product = this.cart.getProduct();
    this.loadProduct(this.product);
    this.loadWindows();
    this.calculatePrice();
  }

  onSubmit() {
    const form = this.productParams.value;
    const product = this.product;
    const newProduct: Product = {
      ...product,
      selectedParams: {
        ...form,
        window: Number(form.window)
      }
    };
    this.cart.addProductToList(newProduct);
    this.router.navigate(['/']);
  }

  loadWindows() {
    this.http.get('/assets/windows.json').subscribe((data: Window[]) => {
      this.windowsList = data;
    });
  }

  loadProduct(product: Product) {
    this.productParams.patchValue({
      width: product.sizeX[0],
      height: product.sizeY[0],
      ...product
    });
    this.inputMinWidth = product.sizeX[0];
    this.inputMinHeight = product.sizeY[0];
    this.inputMaxWidth = product.sizeX.slice(-1)[0];
    this.inputMaxHeight = product.sizeY.slice(-1)[0];
  }

  calculatePrice() {
    this.pricesGuard();
    const selectedWidth: number = Number(this.productParams.value.width);
    const selectedHeight: number = Number(this.productParams.value.height);
    const constructionArea: number = ((selectedWidth * selectedHeight) / 1000000) / 2;
    const selectedWindowId: number = Number(this.productParams.value.window);
    let selectedWindow: Window = { id: 0, price: 0, name: '' };
    if(selectedWindowId) {
      selectedWindow = this.windowsList.find(windowId => windowId.id === selectedWindowId);
    }
    const priceFromDB = this.product.prices.find(price => {
      return selectedWidth >= price.minWidth
      && selectedWidth <= price.maxWidth
      && selectedHeight >= price.minHeight
      && selectedHeight <= price.maxHeight;
    });
    const constructionPriceCalculated = priceFromDB.price * 4;
    const windowPriceCalculated = (constructionArea * selectedWindow.price) * 2.5;
    const productPriceCalculated = priceFromDB ? (constructionPriceCalculated + windowPriceCalculated) : 0;
    const totalPriceCalculated = productPriceCalculated * this.productParams.value.quantity;
    this.productParams.patchValue({
      constructionPrice: Math.round(constructionPriceCalculated),
      windowPrice: Math.round(windowPriceCalculated),
      productPrice: Math.round(productPriceCalculated),
      totalPrice: Math.round(totalPriceCalculated)
    });
    // console.log(this.productParams.value);
  }

  pricesGuard() {
    const selectedWidth: number = Number(this.productParams.value.width);
    const selectedHeight: number = Number(this.productParams.value.height);
    const quantity: number = Number(this.productParams.value.quantity);
    if(selectedWidth < this.inputMinWidth) {
      this.productParams.patchValue({ width: this.inputMinWidth });
    } else if (selectedWidth > this.inputMaxWidth) {
      this.productParams.patchValue({ width: this.inputMaxWidth });
    }
    if(selectedHeight < this.inputMinHeight) {
      this.productParams.patchValue({ height: this.inputMinHeight });
    } else if (selectedHeight > this.inputMaxHeight) {
      this.productParams.patchValue({ height: this.inputMaxHeight });
    }
    if(quantity < 1) {
      this.productParams.patchValue({ quantity: 1 });
    }
  }

  changeInputValue(option) {
    switch (option) {
      case 'widthUp':
        this.productParams.patchValue({ width: this.productParams.value.width + 100 })
        break;
      case 'widthDown':
        this.productParams.patchValue({ width: this.productParams.value.width - 100 })
        break;
      case 'heightUp':
        this.productParams.patchValue({ height: this.productParams.value.height + 100 })
        break;
      case 'heightDown':
        this.productParams.patchValue({ height: this.productParams.value.height - 100 })
        break;
      case 'quantityUp':
        this.productParams.patchValue({ quantity: this.productParams.value.quantity + 1 })
        break;
      case 'quantityDown':
        this.productParams.patchValue({ quantity: this.productParams.value.quantity - 1 })
        break;
    }
    this.calculatePrice();
  }


}

