import { HttpClient } from '@angular/common/http';
import { CartService } from '../shared/services/cart.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Product } from '../shared/models/product.model';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  products: Array<Product>;

  constructor(private router: Router, private cart: CartService, private http: HttpClient) { }

  ngOnInit() {
    this.http.get('/assets/products.json').subscribe((data: Array<Product>) => {
      this.products = data;
    })
  }

  selectProduct(item: Product) {
    this.cart.updateProduct(item);
    this.router.navigateByUrl('/details');
  }

}
