import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user$ = new Subject<boolean>();

  constructor(private router: Router) { }

  login(form) {
    if (form.value.login === 'klient' && form.value.password === 'gvg2019') {
      this.user$.next(true);
      this.router.navigate(['/']);
    }
  }

  getUser(): Observable<boolean> {
    return this.user$.asObservable();
  }

  // Auto Login
  autoLogin() {
    this.user$.next(true);
    this.router.navigate(['/']);
  }

}
